var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.author.publishedAs.length > 0),expression:"author.publishedAs.length > 0"}]},[_c('BButton',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-published-as",modifiers:{"collapse-published-as":true}}],staticClass:"mb-1",attrs:{"variant":"link"},on:{"click":function($event){_vm.opened = !_vm.opened}}},[_c('FontAwesomeIcon',{staticClass:"icon-spin mr-1",class:{'icon-spin-up': _vm.opened},attrs:{"icon":"chevron-down","role":"presentation","aria-hidden":"true"}}),_vm._v(" Published under "+_vm._s(_vm.author.publishedAs.length)+" "),(_vm.author.publishedAs.length === 1)?_c('span',[_vm._v("name")]):_c('span',[_vm._v("names")])],1),_c('BCollapse',{attrs:{"id":"collapse-published-as"}},[_c('Card',{attrs:{"title":"Published as"}},[_c('BTableSimple',{attrs:{"small":"","striped":""}},[_c('BThead',[_c('BTr',[_c('BTh',{staticClass:"d-none d-md-table-cell"},[_vm._v(" Name ")]),_c('BTh',{staticClass:"text-center d-none d-md-table-cell"},[_vm._v(" Publications ")]),_c('BTh',{staticClass:"text-center d-none d-md-table-cell"},[_vm._v(" Citations ")]),_c('BTh',{staticClass:"text-center d-none d-md-table-cell"},[_vm._v(" Earliest Publication ")])],1)],1),_c('BTbody',_vm._l((_vm.variationsInfo),function(item,authorIndex){return _c('BTr',{key:authorIndex},[_c('BTd',[_c('div',{staticClass:"font-weight-bold d-md-none"},[(_vm.disableLinks)?_c('div',[_vm._v(" "+_vm._s(item.name)+" ")]):_c('RouterLink',{attrs:{"to":{
                      name: 'PublicationsSearch',
                      query: {
                        query: `auid:${_vm.author.id} au:'${item.name}'`,
                      },
                    }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('div',{staticClass:"d-none d-md-block"},[(_vm.disableLinks)?_c('div',[_vm._v(" "+_vm._s(item.name)+" ")]):_c('RouterLink',{attrs:{"to":{
                      name: 'PublicationsSearch',
                      query: {
                        query: `auid:${_vm.author.id} au:'${item.name}'`,
                      },
                    }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('BRow',{staticClass:"d-md-none"},[_c('BCol',{staticClass:"text-center"},[_vm._v(" Pubs. "+_vm._s(item.publications)+" ")]),_c('BCol',{staticClass:"text-center"},[_vm._v(" Citations "+_vm._s(item.citations)+" ")]),_c('BCol',{staticClass:"text-center"},[(item.earliestPublication.year)?_c('span',[_c('span',[_vm._v("Earliest Publication")]),_c('span',[(_vm.disableLinks)?_c('div',[_vm._v(_vm._s(item.earliestPublication.year))]):_c('RouterLink',{attrs:{"to":{
                            name: 'ArticlePage',
                            query: {
                              mr: item.earliestPublication.paperId,
                            }
                          }}},[_vm._v(_vm._s(item.earliestPublication.year))])],1)]):_vm._e()])],1)],1),_c('BTd',{staticClass:"text-center d-none d-md-table-cell"},[_vm._v(" "+_vm._s(item.publications)+" ")]),_c('BTd',{staticClass:"text-center d-none d-md-table-cell"},[_vm._v(" "+_vm._s(item.citations)+" ")]),_c('BTd',{staticClass:"text-center d-none d-md-table-cell"},[(item.earliestPublication.year)?_c('span',[(_vm.disableLinks)?_c('div',[_vm._v(_vm._s(item.earliestPublication.year))]):_c('RouterLink',{attrs:{"to":{
                      name: 'ArticlePage',
                      query: {
                        mr: item.earliestPublication.paperId,
                      }
                    }}},[_vm._v(_vm._s(item.earliestPublication.year))])],1):_c('span',[_vm._v("-")])])],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }