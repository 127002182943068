
import { Vue, Component, Prop, Watch, VModel } from 'vue-property-decorator'
import ResultsComponent from './ResultsComponent.vue'
import { Results, SearchParams } from '@/global-types'
import { Journal } from './types'
import { SerialsAPI } from '@/api'
import { updateRouter } from './utils'
import { Card } from '@/components/card'
import { trackJournalsSearchCounter5 } from '@/counter5-tracker'
import { LastSearchTypeOfItem, RESET_LAST_SEARCH_STATE, UPDATE_LAST_SEARCH_ERROR_MESSAGE, UPDATE_LAST_SEARCH_HAS_ERRORS, UPDATE_LAST_SEARCH_NOT_MATCHING, UPDATE_LAST_SEARCH_TYPE_OF_ITEM } from '@/store'
import { AxiosError } from 'axios'
import { ErrorMessage } from '@/api/types'

@Component({
  components: {
    ResultsComponent,
    Card,
  },
})
export default class SerialsResults extends Vue {
  @VModel() searchParams!: SearchParams

  @Prop({ default: false, type: Boolean }) nestedComponentFormat!: boolean

  //
  // REACTIVE PROPERTIES
  //

  serialsResults: Results<Journal> = { total: 0, results: [] }
  loading = false
  concatResults = false
  pageSize = 20
  pageSizeKey = 'journalPageSize'

  /* -------------------------------------------------------------------------- */
  /*                                  WATCHERS                                  */
  /* -------------------------------------------------------------------------- */
  @Watch('searchParams', { deep: true, immediate: true })
  onSearchParamsChanged(newParams, oldParams) {
    if (newParams.pageNumber === 1) {
      this.concatResults = false
    }

    // scroll window to the top
    if (!this.concatResults) {
      window.scrollTo(0, 0) // Only scroll to the top if you're not clicking "more"
    }

    const trackCounter5 = !this.nestedComponentFormat &&
      (oldParams === undefined ||
        newParams.query !== oldParams.query ||
        newParams.facets !== oldParams.facets
      )

    this.fetchData(trackCounter5)
  }

  @Watch('authorSerialsIds')
  onAuthorSerialsIdsChanged() {
    this.fetchData()
  }

  /* -------------------------------------------------------------------------- */
  /*                               LIFECYCLE HOOKS                              */
  /* -------------------------------------------------------------------------- */
  mounted() {
    this.$store.dispatch(RESET_LAST_SEARCH_STATE)
    this.$store.commit(UPDATE_LAST_SEARCH_TYPE_OF_ITEM, LastSearchTypeOfItem.JOURNAL)
    this.pageSize = this.searchParams.pageSize
  }

  /* -------------------------------------------------------------------------- */
  /*                             COMPUTED PROPERTIES                            */
  /* -------------------------------------------------------------------------- */
  get totalPaginationRows() {
    return this.serialsResults.total > 1000 ? 1000 : this.serialsResults.total
  }

  get notMatchingResults() {
    return this.$store.getters.lastSearchNotMatchingResults
  }

  set notMatchingResults(value: boolean) {
    this.$store.commit(UPDATE_LAST_SEARCH_NOT_MATCHING, value)
  }

  get showError() {
    return this.$store.getters.lastSearchHasErrors
  }

  set showError(value: boolean) {
    this.$store.commit(UPDATE_LAST_SEARCH_HAS_ERRORS, value)
  }

  get errorMessage() {
    return this.$store.getters.lastSearchErrorMessage
  }

  set errorMessage(value: string) {
    this.$store.commit(UPDATE_LAST_SEARCH_ERROR_MESSAGE, value)
  }

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */

  ifQueryNonEmpty(run: () => void) {
    if (this.searchParams.query.trim().length > 0) {
      run()
    }
  }

  async fetchData(trackCounter5 = false) {
    this.ifQueryNonEmpty(async() => {
      this.notMatchingResults = false
      this.loading = true
      this.showError = false

      try {
        let serialsResults = await SerialsAPI.searchJournals(
          this.searchParams.query,
          this.searchParams.pageNumber,
          this.searchParams.pageSize,
          this.searchParams.sortBy,
          this.searchParams.orderBy
        )

        if (this.concatResults) {
          serialsResults = {
            results: [...this.serialsResults.results, ...serialsResults.results],
            total: this.serialsResults.total + serialsResults.total,
          }
        }

        if (serialsResults.total === 0) {
          this.notMatchingResults = true
        }

        if (
          !this.nestedComponentFormat &&
          serialsResults.results &&
          serialsResults.results.length === 1 &&
          this.searchParams.pageNumber === 1
        ) {
          const item = serialsResults.results[0]

          this.$router.replace({
            name: 'SerialProfile',
            query: { journalId: item.id.toString() },
          })
        } else {
          this.serialsResults = serialsResults
        }

        if (trackCounter5) trackJournalsSearchCounter5()
      } catch (e) {
        if (e instanceof AxiosError) {
          try {
            this.errorMessage = ((e as AxiosError).response?.data as ErrorMessage).message
          } catch (e2) {
            this.errorMessage = (e as AxiosError).response?.data as string
          }
        }

        this.showError = true
      }

      this.loading = false
    })
  }

  updateURLParams(searchParams: SearchParams, replace?: boolean) {
    if (!this.nestedComponentFormat) {
      updateRouter(this.$router, replace)(searchParams)
    } else {
      this.searchParams = searchParams
    }
  }

  updateCurrentPage(pageNumber: number) {
    this.concatResults = false
    this.updateURLParams({ ...this.searchParams, pageNumber })
  }

  updateInternalPageSize(pageSize: number) {
    this.pageSize = pageSize
    this.searchParams.pageNumber = 1
    this.searchParams.pageSize = pageSize
    this.updateURLParams({ ...this.searchParams, pageSize })
    localStorage.setItem(this.pageSizeKey, pageSize.toString())
  }

  updateSortBy(sortBy: string) {
    this.updateURLParams({ ...this.searchParams, sortBy })
  }

  updateOrderBy(orderBy: string) {
    this.updateURLParams({ ...this.searchParams, orderBy })
  }

  getMoreResults() {
    this.concatResults = true
    this.updateURLParams({ ...this.searchParams, pageNumber: this.searchParams.pageNumber + 1 })
  }
}
